import React from 'react'
import LayoutTwo from '../components/layoutTwo'
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import OffPlanDescStatic from '../components/OffPlanDesc/OffPlanDescStatic';
import SEO from '../components/seo';
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const OffplanDetailBannerStatic = loadable(() => import("../components/NewHomesDetailsBanner/OffplanDetailBannerStatic"));
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"));
const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModule"));

const offplanTemplate = ({ data, children }) => {
  const PageData = data?.strapiPage
  const url = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <LayoutTwo>
      <div className="layout-padding-top">
        <BreadcrumbModule parentname={PageData.choose_menu[0]?.strapi_parent?.title} parent={PageData.choose_menu[0]?.strapi_parent?.slug} pagename={PageData?.title} />

        {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules.map((module) => {
          if (module.more_images && module.more_images.length > 0) {
            const moreImages = module.more_images; // Extract more_images array
            return (
              <OffplanDetailBannerStatic
                title={PageData?.title}
                moreImages={moreImages} // Pass the extracted images here
                {...PageData.banner}
                id={PageData.strapi_id}
              />
            );
          }
          return null;
        })}

        {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
          return (
            <>
              {module.strapi_component === "page-modules.offplan-module" && <OffPlanDescStatic {...module} pageurl={url} propImg={module.more_images} />}
              {module.strapi_component === "page-modules.image-and-content" && module.background_color_transparent && <TileBlock {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} />}
              {module.strapi_component === "page-modules.image-and-content" && !module.background_color_transparent && <ValuationModule {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} />}
            </>
          )
        })}

      </div>
    </LayoutTwo>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage  
  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
  )
}
export default offplanTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
              ...ImageAndContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_OFFPLAN_MODULE {
              ...StaticOffplanFragment
            }
        }
    }
  }
`